<template>
  <div>
    <div class="container">
      <!-- Collections Table -->
      <div class="row g-0">
        <div class="col-12">
          <h1
            class="display-5 mt-5 mb-3 d-flex justify-content-center align-items-center"
          >
            <span class="highlighted me-auto">Collections.</span>
          </h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row g-0">
        <div class="col-12 mb-5">
          <div class="table-responsive" style="min-height: 35vh !important">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th style="white-space: nowrap">Name</th>
                  <th style="white-space: nowrap">Email</th>
                  <th style="white-space: nowrap">Phone</th>
                  <th style="white-space: nowrap">Platform ID</th>
                  <th style="white-space: nowrap">Payment Balance</th>
                  <th style="white-space: nowrap">Charging Balance</th>
                  <th style="white-space: nowrap">Return At</th>
                  <th style="white-space: nowrap">Status</th>
                  <th style="white-space: nowrap">Notice</th>
                  <th style="white-space: nowrap">Action</th>
                  <th style="white-space: nowrap">View</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="collection in $store.state.user.collections"
                  :key="'collections-' + collection.id"
                >
                  <td class="align-middle">
                    {{
                      $store.state.user.reservations.find(
                        (r) => r.platform_id === collection.platform_id
                      ).customer_first_name
                    }}
                    {{
                      $store.state.user.reservations.find(
                        (r) => r.platform_id === collection.platform_id
                      ).customer_last_name
                    }}
                  </td>
                  <td class="align-middle">
                    {{
                      $store.state.user.reservations.find(
                        (r) => r.platform_id === collection.platform_id
                      ).email
                    }}
                  </td>
                  <td class="align-middle">
                    {{
                      $store.state.user.reservations.find(
                        (r) => r.platform_id === collection.platform_id
                      ).phone
                    }}
                  </td>
                  <td class="align-middle">
                    {{ collection.platform_id }}
                  </td>
                  <td class="align-middle">
                    {{
                      new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(
                        $store.state.user.reservations.find(
                          (r) => r.platform_id === collection.platform_id
                        ).payment_balance
                      )
                    }}
                  </td>
                  <td class="align-middle">
                    {{
                      new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(
                        $store.state.user.reservations.find(
                          (r) => r.platform_id === collection.platform_id
                        ).charging_balance / 100
                      )
                    }}
                  </td>
                  <td
                    class="align-middle"
                    style="white-space: nowrap !important"
                  >
                    {{
                      new Date(
                        $store.state.user.reservations.find(
                          (r) => r.platform_id === collection.platform_id
                        ).return_at
                      ).toLocaleDateString("en-US", {
                        timeZone: $store.state.user.regions.find((r) => {
                          return r.id == $store.state.region;
                        }).timezone,
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })
                    }}
                  </td>

                  <td class="align-middle" style="min-width: 150px !important">
                    <span
                      class="badge badge-tertiary"
                      v-if="collection.status != 'Open'"
                      >{{ collection.status }}</span
                    >
                    <div v-else>
                      <div class="progress" style="height: 20px">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          :style="{
                            width: calculateProgress(collection).value + '%',
                          }"
                          :aria-valuenow="calculateProgress(collection).value"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <span
                        class="mt-3"
                        v-if="
                          collection.first_notice && !collection.final_notice
                        "
                        >Notice #{{
                          calculateProgress(collection).notice
                        }}</span
                      >
                      <span class="mt-3" v-if="collection.final_notice"
                        >Final Notice</span
                      >
                    </div>
                  </td>

                  <td class="align-middle text-center">
                    <div
                      class="btn-group btn-group-sm align-middle"
                      role="group"
                      aria-label="Button group with nested dropdown"
                    >
                      <div class="btn-group" role="group">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          v-if="collection.status == 'Open'"
                        >
                          Select Message
                        </button>
                        <ul class="dropdown-menu">
                          <li v-if="!collection.first_notice">
                            <a
                              class="dropdown-item"
                              @click="sendMessage(collection, 'first_notice')"
                              >Send First Notice</a
                            >
                          </li>
                          <li v-if="!collection.second_notice">
                            <a
                              class="dropdown-item"
                              @click="sendMessage(collection, 'second_notice')"
                              >Send Second Notice</a
                            >
                          </li>
                          <li v-if="!collection.third_notice">
                            <a
                              class="dropdown-item"
                              @click="sendMessage(collection, 'third_notice')"
                              >Send Third Notice</a
                            >
                          </li>
                          <li v-if="!collection.fourth_notice">
                            <a
                              class="dropdown-item"
                              @click="sendMessage(collection, 'fourth_notice')"
                              >Send Fourth Notice</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="sendMessage(collection, 'final_notice')"
                              >Send Final Notice</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>

                  <td class="align-middle text-center">
                    <div
                      class="btn-group btn-group-sm align-middle"
                      role="group"
                      aria-label="Button group with nested dropdown"
                    >
                      <div class="btn-group" role="group">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Action
                        </button>
                        <ul class="dropdown-menu">
                          <li v-if="collection.status != 'Sold'">
                            <a
                              class="dropdown-item"
                              @click="
                                updateCollectionsStatus(collection, 'Sold')
                              "
                              >Mark as Sold</a
                            >
                          </li>
                          <li v-if="collection.status != 'Paid'">
                            <a
                              class="dropdown-item"
                              @click="
                                updateCollectionsStatus(collection, 'Paid')
                              "
                              >Mark as Paid</a
                            >
                          </li>
                          <li v-if="collection.status != 'Open'">
                            <a
                              class="dropdown-item"
                              @click="
                                updateCollectionsStatus(collection, 'Open')
                              "
                              >Mark as Open</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <div
                      class="btn-group btn-group-sm align-middle"
                      role="group"
                      aria-label="Button group with nested dropdown"
                    >
                      <div class="btn-group" role="group">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          View
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              class="dropdown-item"
                              data-bs-toggle="offcanvas"
                              data-bs-target="
                                #reservationOffcanvas
                              "
                              @click="
                                id = $store.state.user.reservations.find(
                                  (r) =>
                                    r.platform_id === collection.platform_id
                                ).id
                              "
                              >Reservation</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    :colspan="11"
                    v-if="
                      $store.state.user.collections == 0 ||
                      !$store.state.user.collections
                    "
                  >
                    No results found.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Reservation View -->
    <div
      class="offcanvas offcanvas-start"
      tabindex="-1"
      id="reservationOffcanvas"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div class="offcanvas-header align-middle">
        <h3 class="highlighted my-0 mt-0 fw-bold">View Reservation</h3>
        <button
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Close
        </button>
      </div>
      <div class="offcanvas-body">
        <ReservationOffcanvas :id="id"></ReservationOffcanvas>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationOffcanvas from "@/components/offcanvas/ReservationOffcanvas.vue";

export default {
  name: "CollectionsView",
  data: function () {
    return {
      id: null,
    };
  },
  components: {
    ReservationOffcanvas,
  },
  mounted: function () {},
  methods: {
    updateCollectionsStatus: function (collection, status) {
      this.$axios
        .post(
          this.$store.state.root_url + "/collection/status",
          {
            id: collection.id,
            status: status,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sendMessage: function (collection, notice) {
      this.$axios
        .post(
          this.$store.state.root_url + "/collection/notice/send",
          {
            id: collection.id,
            notice: notice,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    calculateProgress(collection) {
      let progress = 0;

      if (collection.first_notice) {
        progress = { value: 20, notice: 1 };
      }
      if (collection.second_notice) {
        progress = { value: 40, notice: 2 };
      }
      if (collection.third_notice) {
        progress = { value: 60, notice: 3 };
      }
      if (collection.fourth_notice) {
        progress = { value: 80, notice: 4 };
      }
      if (collection.final_notice) {
        progress = { value: 100, notice: 5 };
      }

      return progress;
    },
  },
};
</script>

<style scoped>
.sticky-column {
  position: sticky; /* Make it sticky on larger screens */
  position: -webkit-sticky; /* Compatibility for older webkit browsers */
  top: 0; /* Necessary for sticky positioning */
  left: 0;
  background-color: white;
  z-index: 2;
}

/* Media query for screens smaller than 768px (Bootstrap md breakpoint) */
@media (max-width: 768px) {
  .sticky-column {
    position: static; /* Non-sticky on small screens */
  }
}
.vehicle-card {
  min-width: 250px !important;
}

.table-responsive {
  overflow-x: auto;
}

.reservation-indicator {
  /* Styles for indicating a reservation on a specific day */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

th {
  white-space: nowrap; /* Prevent wrapping in table headers */
}

/* For sizes smaller than md (768px by default in Bootstrap 5.2) */
@media (max-width: 767.98px) {
  #reservationOffcanvas {
    width: 100% !important;
  }
}
#reservationOffcanvas {
  min-width: 75% !important;
}

thead th {
  position: sticky;
  top: 0;
  background-color: white; /* To ensure the header is not transparent */
  z-index: 1; /* To keep the header above other content */
}
.clickable {
  cursor: pointer;
}
</style>
